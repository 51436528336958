<template>
	<main class="page-container pb-0">
		<section class="mypage2-section">
			<div class="container">
				<div class="mypage2-section-flex">
					<div class="flex-0">
						<!-- LNB 영역 -->
						<div class="mypage2-lnb-header">
							<div class="mypage2-lnb-profile">
								<span class="mypage2-lnb-profile__img">
									<img v-if="session.profileImg" :src="session.profileImg" alt="프로필 사진" />
								</span>
								<button @click="clickMove('myinfo')" class="button-setting">
									<i class="icon-mypage-setting"></i>
									<span class="sr-only">내정보 수정하기</span>
								</button>
								<span class="mypage2-lnb-profile__text">{{ session.userNm }}</span>
							</div>
							<div class="mypage2-lnb-welcome">
								<div class="mypage2-lnb-welcome__text">
									{{ session.userNm }}님<br />
									어서오세요!
								</div>
								<div class="mypage2-lnb-welcome__buttons">
									<button type="button" class="button-default is-small is-rounded" @click="clickUpload">소상공인확인서 올리기</button>
									<span v-if="idntyFileNm" class="button-default is-small is-rounded is-status"> <a @click="clickDownload">소상공인확인서</a> 다운로드</span>
									<input ref="idnty" type="file" @change="uploadIdnty" style="display: none" />
									<button type="button" class="button-default is-small is-rounded" @click="clickMove('myinfo')">내 정보 변경</button>
								</div>
							</div>
						</div>
						<nav class="mypage2-lnb-nav">
							<!-- <button @click="clickMove('home')">홈</button> -->
							<button @click="clickMove('broadcast')" class="button" :class="{ 'is-active': $route.params.path === 'broadcast' }">
								<div class="mypage2-lnb-nav__title">
									판로TV<br />
									구독현황
								</div>
								<div class="mypage2-lnb-nav__footer">
									<strong class="num">{{ smsListSize }}</strong
									><span class="unit">건</span>
								</div>
							</button>
							<!--
							<button @click="clickMove('commu')" class="button" :class="{ 'is-active': $route.params.path === 'commu' }">
								<div class="mypage2-lnb-nav__title">
									커뮤니티<br />
									참여 현황
								</div>
								<div class="mypage2-lnb-nav__footer"><strong class="num">{{commuCount}}</strong><span class="unit">건</span></div>
							</button>
							-->
							<button @click="clickMove('support')" class="button" :class="{ 'is-active': $route.params.path === 'support' }">
								<div class="mypage2-lnb-nav__title">
									지원사업<br />
									참여 현황
								</div>
								<div class="mypage2-lnb-nav__footer">
									<strong class="num">{{ srptCount }}</strong
									><span class="unit">건</span>
								</div>
							</button>
							<button @click="clickMove('lms')" class="button" :class="{ 'is-active': $route.params.path === 'lms' }">
								<div class="mypage2-lnb-nav__title">
									e러닝<br />
									수강 현황
								</div>
								<div class="mypage2-lnb-nav__footer">
									<strong class="num">{{ lmsCount }}</strong
									><span class="unit">건</span>
								</div>
							</button>
							<button v-if="isCampnSent" @click="clickMove('product')" class="button" :class="{ 'is-active': $route.params.path === 'product' || $route.params.path === 'productdetail' }">
								<div class="mypage2-lnb-nav__title">
									제품관리
								</div>
								<div class="mypage2-lnb-nav__footer">
									<strong class="num">{{ campnManprdCnt }}</strong
									><span class="unit">건</span>
								</div>
							</button>
							<button @click="clickMove('selfdx')" class="button" :class="{ 'is-active': $route.params.path === 'selfdx' }">
								<div class="mypage2-lnb-nav__title">
									자가진단<br />
									최근현황
								</div>
								<div class="mypage2-lnb-nav__footer"><strong class="num">{{ diagnCount }}</strong><span class="unit">건</span></div>
							</button>
						</nav>
						<div class="mypage2-lnb-footer">
							<div class="mypage2-lnb-footer__banner color-grey">
								<div class="mypage2-lnb-footer__banner-text">
									<!-- <span>스튜디오 안내 및 예약</span> -->
									<strong>소상공인 전용<br />라이브 스튜디오</strong>
								</div>
								<router-link to="/mypage/reserve" class="button-default is-rounded is-secondary">내 예약현황</router-link>
							</div>
							<div v-if="session.userPattnCd === '200' && session.reTermsAgreYn === 'D'" class="mypage2-lnb-footer__banner">
								<div class="mypage2-lnb-footer__banner-text">
									<!-- <span>온라인 판로진출, 기반 조성</span> -->
									<strong>통합기업회원 전환 <br /></strong>
								</div>
								<!-- <a href="#" class="button-default is-rounded is-secondary" >신청하기</a> -->
								<!-- <router-link custom :to="`/mypage/start`" v-slot="{ href, navigate, isActive, isExactActive }">
									<a :href="href" class="button-default is-rounded is-secondary" >신청하기</a>
								</router-link> -->
								<!-- <router-link to="/convert-membership" class="button-default is-rounded is-secondary">전환하기</router-link> -->
								<a @click="clickMoveUserTypeChange" class="button-default is-rounded is-secondary">전환하기</a>
							</div>
						</div>
					</div>
					<div class="flex-1">
						<!-- 각 페이지 영역 -->
						<Home v-if="path === 'home'"></Home>
						<Broadcast v-else-if="path === 'broadcast'"></Broadcast>
						<Commu v-else-if="path === 'commu'"></Commu>
						<Support v-else-if="path === 'support'"></Support>
						<Lms v-else-if="path === 'lms'"></Lms>
						<Myinfo v-else-if="path === 'myinfo'"></Myinfo>
						<ProductManage v-else-if="path === 'product'" :campn-list="campnList"></ProductManage>
						<ProductManageDetail v-else-if="path === 'productdetail'"></ProductManageDetail>
						<Selfdx v-else-if="path === 'selfdx'" :diagnList="diagnList"></Selfdx>
					</div>
				</div>
			</div>
		</section>
	</main>
</template>

<script>
import { mapGetters } from 'vuex';
import { ACT_GET_LMS_LIST, ACT_GET_MY_SCOMMUNITY_COUNT, ACT_GET_MSS_SPRT_BIZ_HIST_INFO_LIST, ACT_GET_PROG_SMSUSER_LIST, ACT_GET_IDENTITY_FILE, ACT_GET_DIAGN_HIS_LIST, ACT_UPLOAD_IDENTITY, ACT_IDENTITY_FILE_DOWNLOAD, ACT_GET_DHFESTA_MY_CAMPN_LIST } from '@/store/_act_consts';
import { MUT_SHOW_ALERT, MUT_SET_RETURN_ROUTE, MUT_SHOW_RETERMS_MODAL } from '@/store/_mut_consts';
import {} from '@/store/_mut_consts';
import { getItem, getItems, isSuccess, lengthCheck, getCheckItems } from '@/assets/js/utils';
import Home from './Home';
import Broadcast from './Broadcast';
import Commu from './Commu';
import Support from './Support';
import Lms from './Lms';
import Selfdx from './Selfdx';
import Myinfo from './Myinfo';
import ProductManage from './ProductManage';
import ProductManageDetail from './ProductManageDetail';
import { getTokenByKey } from '@/assets/js/jwt.service';

export default {
	name: 'MyPage',
	components: {
		Home,
		Broadcast,
		Commu,
		Lms,
		Selfdx,
		Myinfo,
		Support,
		ProductManage,
		ProductManageDetail,
	},
	data: () => ({
		path: '',
		myPagePaths: [
			{ name: '홈', path: 'home', isShow: true },
			{ name: '판로TV 구독현황', path: 'broadcast', isShow: true },
			{ name: '커뮤니티 참여현황', path: 'commu', isShow: true },
			{ name: '지원사업 참여현황', path: 'support', isShow: true },
			{ name: 'e러닝 수강현황', path: 'lms', isShow: true },
			{ name: '내정보 수정하기', path: 'myinfo', isShow: true },
			{ name: '제품관리', path: 'product', isShow: true },
			{ name: '제품관리 상세', path: 'productdetail', isShow: true },
			{ name: '자가진단 최근현황', path: 'selfdx', isShow: true },
		],
		smsListSize: 0,
		commuCount: 0,
		projectCount: 0,
		lmsCount: 0,
		lmsList: [],
		srptCount: 0,
		srptList: [],
		diagnCount: 0,
		diagnList: [],
		idntyFileNm: '',
        campnList: [],
		isCampnSent: false,
	}),
	computed: {
		...mapGetters('auth', ['isAuth', 'session']),

		isMyPagePath() {
			return this.myPagePaths.map((x) => x.path).includes(this.$route.params.path);
		},
        // isCampnSent () {
        //     return this.campnList.length > 0;
        // },
        campnManprdCnt() {
            return  this.isCampnSent ? (this.campnList.length > 1 ? this.campnList.reduce((acc, cur) => acc.manprdAprvReqCnt + cur.manprdAprvReqCnt) : this.campnList[0].manprdAprvReqCnt) : 0;
        }
	},
	watch: {
		$route(to) {
			if (this.myPagePaths.map((x) => x.path).includes(to.params.path)) this.path = to.params.path;
		},
		session: {
			handler(newVal, oldVal) {
				this.getUserSmsCount();
				//this.getMyCommyCount(); //기능삭제로 미실행처리
				this.getMssSprtBizHistInfoCount(); //중소벤처기업부 지원사업 참여이력
				this.getMssSprtBizHistInfo(); // 지원 사업 참여 현황
				this.getMyLmsCount();
                this.getMyCampnList();
			},
			immediate: true,
		},
		campnList(val) {
			this.isCampnSent = val.length > 0;
		},
	},
	created() {
		this.isLoginCheck();
		if (this.isMyPagePath) this.path = this.$route.params.path;
		this.fileList();
		this.diagnHisList();
	},
	mounted() {},
	methods: {
		isLoginCheck() {
			if (!getTokenByKey('auth_token')) {
				this.$store.commit(`common/${MUT_SHOW_ALERT}`, {
					title: '회원가입 및 로그인이 필요합니다.',
					html: true,
					yesfunc: () => {
						this.$store.commit(`common/${MUT_SET_RETURN_ROUTE}`, this.$route.path);
						this.$router.push({ name: 'Login' });
					},
				});
				return false;
			}
		},
		clickMove(target) {
			this.$router.push({ name: 'MyPage2', params: { path: target } }).catch(() => {});
		},
		async getUserSmsCount() {
			//로그인 정보가 세션에 존재하는 경우
			if (typeof this.session != 'undefined' && !(typeof this.session.userId === 'undefined')) {
				await this.$store
					.dispatch(`broadcast/${ACT_GET_PROG_SMSUSER_LIST}`, {
						userId: this.session.userId,
					})
					.then((res) => {
						var items = getItems(res);
						this.smsListSize = items.length;
					})
					.catch((e) => {
						console.log(e);
					});
			} else {
				this.smsListSize = 0;
			}
		},
		getMssSprtBizHistInfoCount() {
			//당해년도 중소벤처기업부 지원사업중 진행중인건 카운트
			if (typeof this.session != 'undefined' && this.session.prtlBrno) {
				this.$store
					.dispatch(`support/${ACT_GET_MSS_SPRT_BIZ_HIST_INFO_LIST}`, {
						brno: this.session.prtlBrno,
						sprtBizYr: new Date().getFullYear(),
						aplySttusNm: '선정',
						pageNo: 0,
						pageSize: 0,
					})
					.then((res) => {
						this.srptCount = res.items.length;
					})
					.catch((e) => {
						console.log(e);
					});
			} else {
				this.srptCount = 0;
			}
		},
		// 마이페이지>지원 사업 참여 현황
		getMssSprtBizHistInfo() {
			if (typeof this.session != 'undefined' && this.session.prtlBrno) {
				this.$store
					.dispatch(`support/${ACT_GET_MSS_SPRT_BIZ_HIST_INFO_LIST}`, {
						brno: this.session.prtlBrno,
						pageNo: 0,
						pageSize: 0,
					})
					.then((res) => {
						this.srptList = res.items;
					})
					.catch((e) => {
						console.log(e);
					});
			}
		},
		async getMyCommyCount() {
			//로그인 정보가 세션에 존재하는 경우
			if (typeof this.session != 'undefined' && !(typeof this.session.userId === 'undefined')) {
				await this.$store
					.dispatch(`mypage2/${ACT_GET_MY_SCOMMUNITY_COUNT}`, {
						userId: this.session.userId,
					})
					.then((res) => {
						this.commuCount = res.myCmntCount;
					})
					.catch((e) => {
						console.log(e);
					});
			} else {
				this.commuCount = 0;
			}
		},
		async getMyLmsCount() {
			//로그인 정보가 세션에 존재하는 경우
			if (typeof this.session != 'undefined' && !(typeof this.session.userId === 'undefined')) {
				await this.$store
					.dispatch(`auth/${ACT_GET_LMS_LIST}`, {})
					.then((res) => {
                        if (lengthCheck(res)) {
                            var item = getItem(res);
                            this.lmsCount = item.data.count;
                            this.lmsList = item.data.items;
                        }
					})
					.catch((e) => {
						console.log(e);
					});
			} else {
				this.lmsCount = 0;
			}
		},
        async getMyCampnList() {
            //로그인 정보가 세션에 존재하는 경우
			//if (typeof this.session != 'undefined' && !(typeof this.session.userId === 'undefined')) {
				await this.$store
					.dispatch(`mypage2/${ACT_GET_DHFESTA_MY_CAMPN_LIST}`)
					.then((res) => {
                        this.campnList =  getCheckItems(res);
					})
					.catch((e) => {
						console.log(e);
					});
			//} 
        },
		fileList() {
			this.$store.dispatch(`auth/${ACT_GET_IDENTITY_FILE}`).then((res) => {
				if (lengthCheck(res)) {
					const item = getItem(res);
					this.idntyFileNm = item.idntyFileNm;
				}
			});
		},
		diagnHisList() {
			this.$store.dispatch(`diagn/${ACT_GET_DIAGN_HIS_LIST}`).then((res) => {
				if (lengthCheck(res)) {
					const items = getItems(res);
					if(items.length > 3){
						this.diagnCount = 3
					}else{
						this.diagnCount = items.length
					}
					this.diagnList = items.slice(0, 3);
				}
			});
		},
		clickUpload() {
			this.$refs.idnty.click();
		},
		uploadIdnty() {
			if (this.$refs.idnty.files && this.$refs.idnty.files.length > 0) {
				this.$store.dispatch(`auth/${ACT_UPLOAD_IDENTITY}`, this.$refs.idnty.files).then((res) => {
					if (res.data && isSuccess(res.data)) {
						this.$store.commit(`common/${MUT_SHOW_ALERT}`, {
							title: '소상공인확인서가 업로드 되었습니다.',
							html: true,
							yesfunc: () => {
								this.$router.go();
							},
						});
					} else {
						this.$store.commit(`common/${MUT_SHOW_ALERT}`, { title: '오류가 발생했습니다. 다시 시도해주세요.' });
					}
					this.$refs.idnty.value = '';
				});
			}
		},
		clickDownload() {
			this.downing = true;
			this.$store.dispatch(`auth/${ACT_IDENTITY_FILE_DOWNLOAD}`, this.idntyFileNm);
		},
		clickMoveUserTypeChange() {
			this.$store.commit(`auth/${MUT_SHOW_RETERMS_MODAL}`, {});
		},
	},
};
</script>
